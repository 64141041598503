.App {
  text-align: center;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000050;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: 250ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  /* transform: translateY(0px); */
  transition: /*opacity*/ 250ms ease-in-out;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  /* transform: translateY(-100px); */
  transition: /*opacity*/ 250ms ease-in-out;
}

.Modal {
  position: absolute;
  border-radius: 25px;
  border: 2px solid purple;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 28px;
  padding-right: 28px;
  background-color: #ffffff;
  transform: translateY(20px) scale(1);
  transition: 250ms ease-in-out;
}

.ReactModal__Content--after-open {
  transform: translateY(0px) scale(1);
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateY(20px) scale(1);
}

.k-pdf-export .noExport {
  display: none;
}

.center {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
