.switch-container {
  width: 36px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #3c415d;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  justify-content: flex-start;
  transition: all 0.2s ease-in-out;
}

.switch-text {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.switch-on {
  justify-content: flex-end;
  background-color: #3c415d;
}

.switch-on .switch-thumb {
  background-color: #fff;
}

.switch-thumb {
  width: 12px;
  height: 12px;
  background-color: #ba61ff;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
