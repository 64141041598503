.inputSearchWithIcon {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2.5rem;
  padding-right: 0.5rem;
  border-radius: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  background-color: white;
  color: #000;
  outline: none;
  box-shadow: 0px 16px 24px 0px #3031331a;
}
