.mentem-table {
  width: 100%;
}

.mentem-table-highlight tr:not(.dummy):hover td {
  background-color: #f4e7ff !important;
}
.mentem-table-highlight tr:not(.dummy):hover td:nth-child(2n) {
  background-color: #eaddf6 !important;
}

.mentem-table-header-1 {
  background: #f3f3f5;
  height: 36px;
  padding-left: 6px;
  padding-right: 6px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.mentem-table-header-2 {
  background: #fff;
  height: 36px;
  padding-left: 6px;
  padding-right: 6px;
  border-left: 1px solid #f3f3f5;
  border-right: 1px solid #f3f3f5;
  border-bottom: 1px solid #f3f3f5;
}

.mentem-table-cell-1 {
  background: #f3f3f5;
  /* height: 52px; */
  /* padding-left: 6px; */
  /* padding-right: 6px; */
  border: 1px solid #fff;
  position: relative;
}

.mentem-table-cell-2 {
  background: #fff;
  /* height: 52px; */
  /* padding-left: 6px; */
  /* padding-right: 6px; */
  border: 1px solid #f3f3f5;
  position: relative;
}

.mentem-table > tbody > tr > td {
  padding-left: 6px;
  padding-right: 6px;
}

.mentem-table > tbody > tr > td > div {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
}

.mentem-table > thead > tr > th {
  /* white-space: nowrap; */
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
}

.mentem-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  border-radius: 6px;
  border: 0.3px solid #6b69c1;
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(48, 49, 51, 0.05);
  color: #000;
  z-index: 10;
}

.table-scrollbar {
  overflow: auto;
}

/* Show scrollbar on hover */
.table-scrollbar:hover {
  overflow: auto;
}

/* Hide the default scrollbar */
.table-scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Track */
.table-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.table-scrollbar::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 66%, white 33%);
}

.table-scrollbar::-webkit-scrollbar-thumb:horizontal {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 66%, white 33%);
}

.table-scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}

/* Handle */
.table-scrollbar-grey::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 66%, #e1e1e5 33%);
}

.table-scrollbar-grey::-webkit-scrollbar-thumb:horizontal {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 66%, #e1e1e5 33%);
}

.table-scrollbar-grey::-webkit-scrollbar-corner {
  background: transparent;
}
