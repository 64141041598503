.container {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  padding-top: 5vh;
  padding-bottom: 100px;
  padding-left: 2vw;
  padding-right: 2vw;
  /* min-height: 100vh; */
  width: 100%;
  height: 100vh;
  /* max-width: 100vw; */
  /* box-sizing: border-box; */
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: scroll;

  /* z-index: 10; */

  /* padding-top: 55px; */
  /* padding-bottom: 55px; */
  /* z-index: 10; */
}

.content {
  width: 100%;
  /* max-width: 1024px; */
  background-color: rgba(230, 230, 230, 0.76);
  /* background-color: rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 25px 56px 0px rgba(0, 0, 0, 0.1), 0px 100px 100px 0px rgba(0, 0, 0, 0.1);

  padding-top: 3vh;
  padding-left: 2vw;
  padding-right: 2vw;
  /* padding-top: 80px; */
  padding-bottom: 40px;
  border-radius: 12px;

  /* width: max-content; */
  /* padding-bottom: 40px; */
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 0px;
  /* width: 100%; */
  box-sizing: border-box;

  /* height: 100%; */
  /* min-height: 100vh; */
  /* z-index: 10; */
}

div .textarea {
  font-size: 16px;
  font-family: 'Inter';
  padding: 16px;
  width: 100%;
  transition: all 0.1s;
  resize: none;
  box-sizing: border-box;

  /* slot */
  /* background-image: linear-gradient(
    to right,
    rgb(255, 255, 255),
    rgb(255, 225, 255)
  ); */
  background-color: rgba(255, 255, 255, 1);
  border: none !important;
  border-radius: 3px;
  box-shadow: inset 0px 1px 0px 0px rgba(125, 129, 159, 0.9);
  outline-style: none;
}

.question {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 16px;
}
.answer {
  font-size: 18px;
  padding-bottom: 16px;
}

.submitbutton {
  align-self: center;
  width: min-content;
  font-size: 16px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(186, 97, 255, 1);
  font-weight: bold;
  height: 32px;
  /* padding-top: 6px; */
  padding-left: 12px;
  padding-right: 12px;
  /* padding-bottom: 6px; */
  border-radius: 200px;
  border: none;
  cursor: pointer;
}

.submitbutton.disabled {
  background-color: rgba(180, 180, 180, 1);
  cursor: not-allowed;
}
