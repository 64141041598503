.dropdown {
  position: relative;
  display: inline-block;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 6px;
  min-width: 188px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* padding: 12px 16px; */
  margin-top: 6px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.mentem-dropdown-button {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 16px;
}

.mentem-dropdown-popup-list-label {
  color: #787676;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
