html,
body {
  overscroll-behavior-x: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer components {
  .page-title {
    @apply text-4xl font-semibold text-white underline;
  }
  .page-title-v2 {
    @apply text-4xl font-semibold text-white;
  }
  .section-heading {
    @apply text-2xl font-semibold text-white;
  }
  .text-color {
    @apply text-white;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  background: url('images/close2.svg') no-repeat center center;
  background-size: contain;
}

.eoi-title {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.eoi-subtitle {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.button-primary {
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  border: 1px solid #ba61ff;
  background: #ba61ff;
  color: #fff;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.button-primary:disabled {
  color: #fff;
  border: 0.3px solid #d5d5db;
  background: rgba(120, 118, 118, 0.56);
  box-shadow: none;
}

.button-primary:not(:disabled):hover {
  color: #3c415d;
  background: #fff;
  box-shadow: 0px 16px 24px 0px #3031331a;
  border: 1px solid #d5d5db;
}

.button-primary-v2 {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #ba61ff;
  background: #ba61ff;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.button-secondary-v2 {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #000;
  background: #fff;
  box-shadow: 0px 16px 24px 0px rgba(13, 13, 13, 0.2);
  border: 1px solid #f4f4f4;
}

.button-secondary {
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #3c415d;
  background: #fff;
  box-shadow: 0px 16px 24px 0px #3031331a;
  border: 1px solid #d5d5db;
}

.button-secondary:disabled {
  color: #fff;
  border: 0.3px solid #d5d5db;
  background: rgba(120, 118, 118, 0.56);
  box-shadow: none;
}

.button-secondary:not(:disabled):hover {
  background: #ba61ff;
  color: #fff;
  border: 1px solid #ba61ff;
}

.button-secondary svg {
  fill: #bd69fe;
}

.button-secondary:hover svg {
  fill: #fff;
}

.button-secondary-stroke svg {
  fill: none;
  stroke: #bd69fe;
}

.button-secondary-stroke:hover svg {
  fill: none;
  stroke: #fff;
}

.input-text {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 12px;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  background: #fff;
  width: 100%;
  outline: none;
}

.input-text::placeholder {
  color: #787676;
}

.jer-input-text {
  min-height: 40px; /* Set a minimum height */
  height: auto; /* Allow the height to adjust automatically */
  overflow: visible; /* Prevent scrollbars */
  resize: none; /* Disable manual resizing */
  white-space: pre-wrap; /* Preserve line breaks */
  box-sizing: border-box;
  /* box-sizing: content-box; */
  font-size: 18px;
}

.svg-container {
  display: inline-block;
  cursor: pointer;
}

.svg-container .svg-path {
  fill: black;
  transition: fill 0.3s ease;
}

.svg-container:hover .svg-path {
  fill: #ba61ff;
}

.svg-container .svg-path-stroke {
  stroke: black;
  transition: fill 0.3s ease;
}

.svg-container:hover .svg-path-stroke {
  stroke: #ba61ff;
}

.sis-button-change-status {
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid #f4f4f4;
  background: #fff;
  box-shadow: 0px 16px 24px 0px rgba(13, 13, 13, 0.2);
  color: #000;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sis-button-change-status:hover {
  background: #ba61ff;
  color: #fff;
  border: 1px solid #ba61ff;
}
